
              @import "@/assets/css/variables.scss";
            

















































































.page_wrapper {
  position: relative;
  height: calc(100vh - 65px);
  overflow-y: auto;
  overflow-x: hidden;

  .page_wrapper_tab {
    position: sticky;
    top: 0;
    box-shadow: 0px 0px 8px 1px rgba(122, 134, 153, 0.15);
    background: $white;
    padding: 0 4px;
    height: 40px;
    line-height: 40px;
    display: flex;
    overflow-x: auto;
    width: 100%;
    z-index: 100;

    &::-webkit-scrollbar {
      display: none;
    }

    .bar {
      position: absolute;
      bottom: 0;
      left: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: $primary;
      z-index: 1;
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      list-style: none;
    }

    .item {
      width: fit-content;
      font-size: 16px;
      font-weight: 400;
      padding: 0 20px;
      cursor: pointer;
      white-space: nowrap;

      &.is-active {
        font-weight: 700;
        color: $primary;
      }
    }
  }

  .container {
    padding: 16px 24px;

    @include screen-mobile {
      padding: 16px 0;
    }
  }
}
