@mixin loading {
  display: inline-block;
  width: 150px;
  height: 153px;
  background: url($loading) no-repeat center;
  background-size: cover;
}

/** loading for star */
.el-loading-spinner {
  transform: translateY(-50%);
}

.client-portal-loading {
  @include loading;
}

.el-steps {
  .el-step__line {
    border-width: 1px;
    border-color: $bottle-blue;
    border-style: dashed;
  }

  .el-step__icon.is-icon {
    border-radius: 50%;
    background-color: $white;
    width: 27px;
    height: 27px;

    .el-step__icon-inner {
      border-radius: 50%;
      width: 15px;
      height: 15px;
    }

    .red {
      background-color: $red;
    }
    .blue {
      background-color: $bottle-blue;
    }
  }
}

.tab-demo {
  position: relative;

  li {
    display: inline-block;
    background-color: $light-blue;
    width: 295px;
    height: 60px;
    font-size: 15px;
    color: $blue;
    line-height: 1;
    text-align: center;
    border-radius: 4px 4px 0 0;
    position: absolute;
    bottom: -80px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    display: -webkit-flex; /* Safari */
    align-items: center; /* Safari */

    &:first-child {
      @include rtl-sass-prop(left, right, 0);
    }

    &:nth-child(2) {
      @include rtl-sass-prop(left, right, 300px);
    }

    &:nth-child(3) {
      @include rtl-sass-prop(left, right, 600px);
    }

    &.active {
      background-color: $blue;
      box-shadow: 2.007px 2.229px 7.56px 1.44px $gray-blue;
      color: $white;
      width: 295px;
      height: 102px;
    }
  }
}

// leads, rebatePaymentHistory page
.card {
  border-radius: 8px;
  background-color: $white;
  padding: 20px;
}

// ibreport, rebatePaymentHistory, home page
.calendar_content {
  width: 100%;
  margin: 20px 0;

  .calendar {
    width: calc(100% - ((100% - (16px * 3)) / 4));
    display: inline-block;
  }

  .right_box {
    height: 70px;
    width: calc((100% - (16px * 3)) / 4);
    display: inline-block;

    .btn-red {
      width: 100%;
      height: 70px;
    }
  }
}

/* 分页开始 */
.page-box {
  width: 100%;
  padding-top: 30px;

  .el-pagination_box {
    vertical-align: top;
    display: inline-block;
  }

  .el-pagination {
    float: none;

    span {
      font-size: 15px;
      color: #698bb4;
    }

    .el-pagination__total {
      display: inline-block !important;
    }
  }
}
/* 分页结束 */

@media (max-width: 1024px) {
  .tab-demo {
    li {
      font-size: 16px;
      width: 137px;

      &:nth-child(2) {
        @include rtl-sass-prop(left, right, 140px);
      }

      &:nth-child(3) {
        @include rtl-sass-prop(left, right, 280px);
      }

      &.active {
        width: 137px;
      }
    }
  }
}

@media (max-width: 767px) {
  .tab-demo {
    li {
      font-size: 12px;
      height: 29px !important;

      &.active {
        height: 46px !important;
      }
    }
  }

  .card {
    margin-top: 70px !important;
    border-radius: 0 0 8px 8px;
  }

  .calendar_content {
    .calendar {
      width: 100%;
    }

    .right_box {
      width: 100%;
      margin-top: 110px;
    }
  }
}

@media (max-width: 550px) {
  .page-box .el-pagination_box {
    overflow-x: scroll;
    padding-bottom: 15px;
  }

  .page-box .el-pagination_box /deep/ .el-pagination {
    width: 495px;
  }
}
