
              @import "@/assets/css/variables.scss";
            








































.page_search_area {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include screen-mobile {
    flex-direction: column;
  }

  .search_form {
    margin-bottom: -16px;
    width: 100%;

    .el-form,
    .calendar {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @include screen-mobile {
        width: 100%;
      }

      .el-form-item {
        width: 240px;
        margin-bottom: 16px;
        @include rtl-sass-prop(margin-right, margin-left, 16px);

        @include screen-mobile {
          width: 100%;
          @include rtl-sass-prop(margin-right, margin-left, 0px);
        }

        .el-select, .el-cascader, .el-date-editor {
          width: 100%;
        }
      }
    }
  }

  .search_button {
    flex: 0 0 136px;
    @include rtl-sass-value(text-align, right, left);
    padding-top: 30px;

    @include screen-mobile {
      flex: 0 0 48px;
      width: 100%;
      padding-top: 16px;

      .el-button {
        width: 100%;
      }
    }
  }
}
