$url: '~@/assets/images/pug/';
$logo: $url + 'logo.png';
$loading: '~@/assets/images-1/loading.gif';
$menu: $url + 'menu.png';

$grid-breakpoints: (
  xs: 0,
  sm: 550px,
  md: 780px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
);

$max-container-size: map-get($grid-breakpoints, xl);
$max-grid-columns: 24;

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25 // 0.25rem or 4px
    ),
    2: (
      $spacer * 0.5 // 0.5rem or 8px
    ),
    3: $spacer,
    // 1rem or 16px
      4:
      (
        $spacer * 1.5 // 1.5rem or 24px
      ),
    5: (
      $spacer * 3 // 3rem or 48px
    )
  ),
  $spacers
);

$sizes: ();
$sizes: map-merge(
  (
    5: 5%,
    10: 10%,
    15: 15%,
    20: 20%,
    25: 25%,
    30: 30%,
    35: 35%,
    40: 40%,
    45: 45%,
    50: 50%,
    55: 55%,
    60: 60%,
    65: 65%,
    70: 70%,
    75: 75%,
    80: 80%,
    85: 85%,
    90: 90%,
    95: 95%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

$border-color: rgba(39, 5, 109, 0.1);
$border-radius: 0.375rem;
$border-radius-lg: 0.625rem;
$border-radius-sm: 0.2rem;
$border-radius-xs: 0.125rem;
$border-radius-circle: 50%;
$border-radius-round: 50px;
$border-width: 1px;

$box-shadow: 2.007px 2.229px 7.56px 1.44px $gray-blue;

$zindex-0: 0;
$zindex-100: 100;
$zindex-200: 200;
$zindex-300: 300;
$zindex-400: 400;
$zindex-500: 500;
$zindex-600: 600;
$zindex-700: 700;
$zindex-800: 800;
$zindex-900: 900;
