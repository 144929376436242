
              @import "@/assets/css/variables.scss";
            



































.status_message {
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-title;
    font-size: 18px;

    .icon {
      font-size: 26px;
      @include rtl-sass-prop(margin-right, margin-left, 10px);
    }
  }

  .content {
    text-align: center;
    color: $text-secondary;
    font-size: 14px;
    margin-top: 16px;
  }
}
