// usage : @include screen-desktop {...}
@mixin screen-desktop {
  @media only screen and (min-width: map-get($grid-breakpoints, xxl)) {
    @content;
  }
}

// usage : @include screen-laptop {...}
@mixin screen-laptop {
  @media only screen and (max-width: map-get($grid-breakpoints, xxl)) {
    @content;
  }
}

// usage : @include screen-laptop-only {...}
@mixin screen-laptop-only {
  @media only screen and (min-width: map-get($grid-breakpoints, xl)) and (max-width: map-get($grid-breakpoints, xxl)) {
    @content;
  }
}

// usage : @include screen-tablet-above {...}
@mixin screen-tablet-above {
  @media only screen and (min-width: map-get($grid-breakpoints, lg)) {
    @content;
  }
}

// usage : @include screen-tablet {...}
@mixin screen-tablet {
  @media only screen and (max-width: map-get($grid-breakpoints, lg)) {
    @content;
  }
}

// usage : @include screen-mobile-above {...}
@mixin screen-mobile-above {
  @media only screen and (min-width: map-get($grid-breakpoints, md)) {
    @content;
  }
}

// usage : @include screen-mobile {...}
@mixin screen-mobile {
  @media only screen and (max-width: map-get($grid-breakpoints, md)) {
    @content;
  }
}
