
              @import "@/assets/css/variables.scss";
            















































































































































































































.new_drawer_custom_class {
  // @include screen-mobile() {
  //   padding: 25px 30px;
  // }

  .el-drawer__header {
    padding: 16px 24px;
    font-size: 16px;
    line-height: 24px;
    color: $text-title;
    font-weight: 600;
    border-bottom: 1px solid $border-color-base;
    margin: 0;

    .header_content {
      position: relative;
      @include rtl-sass-prop(padding-left, padding-right, 10px);

      &::before {
        content: '';
        position: absolute;
        top: 4px;
        @include rtl-sass-prop(left, right, 0);
        width: 4px;
        height: 16px;
        border-radius: 4px;
        background-color: $primary;
      }
    }

    .el-drawer__close-btn {
      color: $black;
      &:hover {
        color: $primary;
      }
    }
  }

  .drawer_body {
    padding: 16px 24px;
  }

  &.has_footer {
    .drawer_body {
      padding-bottom: 80px;
    }
  }

  .drawer_footer {
    position: absolute;
    bottom: 0;
    @include rtl-sass-prop(right, left, 0);
    padding: 16px 24px;
    width: 100%;
    border-top: 1px solid $border-color-base;
    background-color: $white;

    .footer_content {
      display: flex;
      justify-content: flex-end;
    }
  }
}
