
              @import "@/assets/css/variables.scss";
            






























































.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
