@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }
  a#{$parent} {
    &:hover,
    &:focus {
      background-color: darken($color, 10%) !important;
    }
  }
}

.bg-transparent {
  background-color: transparent !important;
}

@each $color, $value in $theme-colors {
  @include bg-variant('.bg-#{$color}', $value);
}
