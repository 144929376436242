
              @import "@/assets/css/variables.scss";
            












































































































































































.el-form-item.is-error {
  .account_select {
    border-color: $danger;
  }
}
.account_popover {
  padding: 8px;
}
