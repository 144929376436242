@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .float#{$infix}-none {
      float: none !important;
    }

    [dir='ltr'] {
      .float#{$infix}-start {
        float: left !important;
      }
      .float#{$infix}-end {
        float: right !important;
      }
    }

    [dir='rtl'] {
      .float#{$infix}-start {
        float: right !important;
      }
      .float#{$infix}-end {
        float: left !important;
      }
    }
  }
}
