
              @import "@/assets/css/variables.scss";
            




























.help_icon {
  color: $black;
  cursor: pointer;
  @include rtl-sass-prop(margin-left, margin-right, 8px);
  &:hover {
    color: $primary;
  }
}
